import { fetchStream, ProcessMessageCallback } from '../utils/StreamProcessor'
import { eoiApiClient as apiClient } from './configs/AxiosClient'
import { LearnerReport } from './entities/learnerReport.entity'
import { Program } from './entities/program.entity'
import { Submission } from './entities/submission.entity'
import {
  GenerateQuestionSpaceQuestionDTO,
  GetProgramDto,
  PopulateQuestionSpacesDTO,
  ProcessQueryContextDTO,
  SummariseQueryContextDTO,
  UpdateProgramQueryContextDTO
} from './EoiAPI.dto'

const streamApi = process.env.REACT_APP_EOI_API_URL

export enum EmailTemplateType {
  learnerReview = 'learnerReview',
  learnerConfirmation = 'learnerConfirmation',
  managerConfirmation = 'managerConfirmation',
  managerConsent = 'managerConsent'
}

export const EoiAPI = {
  getDimentionalReport: async (programId: string): Promise<any> => {
    const response: any = await apiClient.get(`/programs/report/${programId}`)
    return response.data
  },
  getPrograms: async (): Promise<Program[] | undefined> => {
    const response: any = await apiClient.get(`/programs`)
    return response.data
  },
  getMyPrograms: async (): Promise<Program[] | undefined> => {
    const response: any = await apiClient.get(`/programs/mine`)
    return response.data
  },
  getProgram: async (slug: string): Promise<Program | undefined> => {
    const response: any = await apiClient.get(`/programs/${slug}`)
    return response.data
  },
  getProgramById: async (programId: string): Promise<Program | undefined> => {
    const response: any = await apiClient.get(`/programs/id/${programId}`)
    return response.data
  },
  updateProgram: async (slug: string, body: any): Promise<Program | undefined> => {
    const response: any = await apiClient.patch(`/programs/${slug}/overwrite`, body)
    return response.data
  },
  exportReport: async (programId: string): Promise<any> => {
    const response: any = await apiClient.get(`/programs/${programId}/export`, {
      responseType: 'blob'
    })
    return response.data
  },
  getSubmissions: async (programId: string): Promise<Submission[] | undefined> => {
    const response: any = await apiClient.get(`/submissions/?programId=${programId}`)
    return response.data
  },
  getLearnerReports: async (programId: string): Promise<LearnerReport[] | undefined> => {
    const response: any = await apiClient.get(`/learner/results/${programId}`)
    return response.data
  },
  triggerManagerConfirmation: async (id: string): Promise<any> => {
    const response: any = await apiClient.post(`/learner/${id}/trigger-manager-confirmation`)
    return response.data
  },
  updateLearnerReport: async (id: string, body: any): Promise<LearnerReport | undefined> => {
    // Remove _id from body
    if (body._id) {
      delete body._id
    }
    const response: any = await apiClient.patch(`/learner/${id}`, body)
    return response.data
  },
  sendReminders: async (programId: string, ids: string[]): Promise<any> => {
    const response: any = await apiClient.post(`/learner/send-reminders`, { programId, ids })
    return response.data
  },
  inviteLearnersToCohort: async (ids: string[], availability: string): Promise<any> => {
    const response: any = await apiClient.post(`/learner/invite`, { ids, availability })
    return response.data
  },
  rejectLearnersFromCohort: async (ids: string[]): Promise<any> => {
    const response: any = await apiClient.post(`/learner/reject`, { ids })
    return response.data
  },
  addLearnerReport: async (body: any): Promise<LearnerReport | undefined> => {
    const response: any = await apiClient.post(`/learner`, body)
    return response.data
  },
  getEmailTemplate: async (programId: string, type: EmailTemplateType): Promise<any> => {
    const body = { programId, type }
    const response: any = await apiClient.post(`/learner/email-template`, body)
    return response.data
  },
  sendEmailTemplate: async (
    programId: string,
    type: EmailTemplateType,
    email: string,
    firstName: string,
    lastName: string,
    managerEmail: string,
    managerFirstName: string
  ): Promise<any> => {
    const body = { programId, type, email, firstName, lastName, managerEmail, managerFirstName }
    const response: any = await apiClient.post(`/learner/send-email-template`, body)
    return response.data
  },
  updateProfile: async (programId: string, email: string): Promise<any | undefined> => {
    const body = { program: programId, email }
    const response: any = await apiClient.post(`/learner/profile`, body)
    return response.data
  },

  //context

  getProgramByCode: async (dto: GetProgramDto): Promise<Program> => {
    let url = `/programs/${dto.clientCode}/${dto.programCode}`
    const response: any = await apiClient.get(url)
    return response.data
  },
  generateQuestionSpaceQuestionStream: async (
    dto: GenerateQuestionSpaceQuestionDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any
  ): Promise<any> => {
    const url = `${streamApi}/question/question`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  processQueryContextStream: async (
    dto: ProcessQueryContextDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any
  ): Promise<any> => {
    const url = `${streamApi}/question/context`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  populateQuestionSpacesStream: async (
    dto: PopulateQuestionSpacesDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any
  ): Promise<any> => {
    const url = `${streamApi}/question/populate`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  summariseQueryContextStream: async (
    dto: SummariseQueryContextDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any
  ): Promise<any> => {
    const url = `${streamApi}/question/summary`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  updateProgramQueryContext: async (dto: UpdateProgramQueryContextDTO): Promise<any> => {
    let url = `/programs/${dto.clientCode}/${dto.programCode}/context`
    console.log('url:', url)
    const response: any = await apiClient.patch(url, dto.queryContext)
    return response.data
  }

  // getProgram: async (dto: GetProgramDto): Promise<Program> => {
  //   let url = `/programs/${dto.clientCode}/${dto.programCode}`
  //   const response: any = await apiClient.get(url)
  //   return response.data
  // }
}
