import { useCallback, useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import LoadingIndicator from '../components/LoadingIndicator'
import { EmailTemplateType, EoiAPI } from '../apis/EoiAPI'
import { Program } from '../apis/entities/program.entity'
import Path from '../routes/Path'
import { useAuth } from '../context/AuthProvider'
import { TextareaAutosize } from '@mui/material'
import { toastError, toastSuccess } from '../utils/toast'
import ButtonLoadingIndicator from '../components/ButtonLoadingIndicator'

interface EmailTemplateResponse {
  type: EmailTemplateType
  subject: string
  inlineHtml: string
}

export default function EOI() {
  const [programs, setPrograms] = useState<Program[] | null | undefined>(undefined)
  const { isLogged, isSuperAdmin, isAdmin } = useAuth()
  const [showEdit, setShowEdit] = useState(false)
  const [showEmailTemplate, setShowEmailTemplate] = useState(false)
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null)
  const refSelectedProgramId = useRef<string | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateResponse | null>(null)
  const refSelectedEmailTemplateType = useRef<EmailTemplateType | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const navigate = useNavigate()
  const refBody = useRef<{
    programId?: string
    type?: EmailTemplateType
    email?: string
    firstName?: string
    lastName?: string
    managerEmail?: string
    managerFirstName?: string
  }>({})
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const isAdminPlus = () => {
    return isAdmin() || isSuperAdmin()
  }

  const fetchEmailTemplate = useCallback(async (type: EmailTemplateType) => {
    try {
      const programId = refSelectedProgramId.current
      if (!programId) {
        toastError('Program not found')
        return
      }
      refSelectedEmailTemplateType.current = type
      setIsLoading(true)
      const result = await EoiAPI.getEmailTemplate(programId, type)
      setEmailTemplate({ ...result, type })
      setShowEmailTemplate(true)
    } catch (error) {
      console.error(error)
      toastError('Failed to fetch email template')
    } finally {
      setIsLoading(false)
    }
  }, [])

  const fetchProgramById = useCallback(async (programId: string) => {
    try {
      refSelectedProgramId.current = programId
      const result = await EoiAPI.getProgramById(programId)
      if (result) {
        // filter out the fields that are not allowed to be updated
        const filteredResult = {
          name: result.name,
          client: result.client,
          slug: result.slug,
          openDate: result.openDate,
          closeDate: result.closeDate,
          components: result.components,
          adminHub: result.adminHub,
          emailSettings: result.emailSettings
        } as Program
        setSelectedProgram(filteredResult)
        setShowEdit(true)
      } else {
        toastError('Failed to fetch program data')
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to fetch program data')
    }
  }, [])

  const updateProgram = useCallback(async (slug: string, body: any) => {
    try {
      const result = await EoiAPI.updateProgram(slug, body)
      return result
    } catch (error) {
      console.error(error)
    }
  }, [])

  const fetchData = useCallback(async () => {
    try {
      const result = await EoiAPI.getPrograms()
      const filteredPrograms = result?.filter(program => !program.devOnly)
      setPrograms(filteredPrograms)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const onClickEdit = async (program: Program) => {
    await fetchProgramById(program._id)
  }

  const onClickContext = async (program: Program) => {
    navigate(`/eoi/context/${program.slug}`)
  }

  const onUpdateProgram = async (slug: string) => {
    try {
      if (!textareaRef.current) {
        return
      }
      setIsSaving(true)
      const body = JSON.parse(textareaRef.current.value)
      const result = await updateProgram(slug, body)
      if (result) {
        toastSuccess('Program updated successfully')
      } else {
        toastError('Failed to update program')
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to update program')
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (isLogged) {
      fetchData()
    }
  }, [fetchData, isLogged])

  const renderClients = () => {
    return programs?.map((item, i) => {
      const url = Path.eoiProgramReport.path.replace(':clientCode/:programCode', item.slug)
      return (
        <div className="flex flex-col gap-2" key={`program-${i}`}>
          <Link to={url} key={item._id}>
            <div className="rounded-lg bg-white px-4 py-4 shadow">
              <div className="pl-4 pr-4 text-xl font-semibold text-gray-900 h-10 flex items-center justify-center">
                {item.client}
              </div>
              <div className="pl-4 pr-4 text-2xl font-semibold text-gray-900 h-16 flex items-center justify-center">
                {item.name}
              </div>
            </div>
          </Link>
          <div className="flex justify-end p-1">
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', width: '100%' }}>
              {isSuperAdmin() && item.queryContext ? (
                <button style={{ fontSize: '14px' }} className="text-white" onClick={() => onClickContext(item)}>
                  AI query context
                </button>
              ) : (
                <div />
              )}
              <button style={{ fontSize: '14px' }} className="text-white" onClick={() => onClickEdit(item)}>
                {isAdminPlus() ? 'Manage' : 'View'} content
              </button>
            </div>
          </div>
        </div>
      )
    })
  }

  const renderEmailTemplateTitle = () => {
    switch (emailTemplate?.type) {
      case EmailTemplateType.learnerReview:
        return 'Learner review'
      case EmailTemplateType.learnerConfirmation:
        return 'Learner confirmation'
      case EmailTemplateType.managerConfirmation:
        return 'Manager confirmation'
      case EmailTemplateType.managerConsent:
        return 'Manager consent'
      default:
        return ''
    }
  }

  const renderEmailTemplateStatus = () => {
    let status = false
    switch (emailTemplate?.type) {
      case EmailTemplateType.learnerReview:
        status = selectedProgram?.emailSettings?.learnerReview
        break
      case EmailTemplateType.learnerConfirmation:
        status = selectedProgram?.emailSettings?.learnerConfirmation
        break
      case EmailTemplateType.managerConfirmation:
        status = selectedProgram?.emailSettings?.managerConfirmation
        break
      case EmailTemplateType.managerConsent:
        status = selectedProgram?.emailSettings?.managerConsent
        break
      default:
        break
    }
    return `These emails are currently ${status ? 'enabled' : 'disabled'}`
  }

  const sendEmail = async () => {
    try {
      setIsSendingEmail(true)
      const programId = refSelectedProgramId.current
      if (!programId) {
        toastError('Program not found')
        return
      }
      if (!refSelectedEmailTemplateType.current) {
        toastError('Email template not found')
        return
      }
      if (!refBody.current.email) {
        toastError('Email is required')
        return
      }

      const result = await EoiAPI.sendEmailTemplate(
        programId,
        refSelectedEmailTemplateType.current,
        refBody.current.email,
        refBody.current.firstName || '{FirstName}',
        refBody.current.lastName || '{LastName}',
        refBody.current.email, // reuse email as manager email
        refBody.current.managerFirstName || '{ManagerFirstName}'
      )

      if (result) {
        toastSuccess('Test email sent successfully')
      } else {
        toastError('Failed to send test email')
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to send test email')
    } finally {
      setIsSendingEmail(false)
    }
  }

  const divHeight = () => {
    return isAdminPlus() ? 'h-3/4' : 'h-auto pb-[36px]'
  }

  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center justify-center gap-8 bg-purple-950 p-4 relative">
        {programs === undefined && <LoadingIndicator />}
        {programs && (
          <>
            <div className="page-title">Programs</div>
            <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3">{renderClients()}</div>
          </>
        )}
        {showEdit && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
            <div
              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 w-3/4 ${divHeight()}`}>
              {' '}
              <div className="flex justify-end text-2xl font-bold">
                <button onClick={() => setShowEdit(false)}>X</button>
              </div>
              <div className="flex flex-col text-2xl font-bold">
                <span>
                  {selectedProgram?.client} - {selectedProgram?.name}
                </span>
                {isAdminPlus() ? (
                  <span className="text-[12px] font-normal">
                    Only allow to update name, openDate, closeDate, components, adminHub and emailSettings
                  </span>
                ) : (
                  <span className="text-[18px] font-normal pt-[12px]">Email preview</span>
                )}
              </div>
              {isAdminPlus() && (
                <>
                  <br />
                  <div className="w-full relative min-h-[70%] max-h-[70%] overflow-y-auto">
                    <TextareaAutosize
                      ref={textareaRef}
                      className="w-full border border-gray-300 text-[14px] focus:outline-none"
                      style={{ height: '1000px', resize: 'none' }}
                      defaultValue={JSON.stringify(selectedProgram, null, 2)}
                    />
                  </div>
                </>
              )}
              <div className="flex flex-row items-center justify-center gap-4">
                <button
                  className="bg-blue-500 text-white mt-4 p-2 rounded-lg hover:bg-blue-600 w-[170px]"
                  onClick={() => fetchEmailTemplate(EmailTemplateType.learnerReview)}
                  disabled={isLoading}>
                  {isLoading && refSelectedEmailTemplateType.current === EmailTemplateType.learnerReview ? (
                    <ButtonLoadingIndicator />
                  ) : (
                    'Learner review'
                  )}
                </button>
                <button
                  className="bg-blue-500 text-white mt-4 p-2 rounded-lg hover:bg-blue-600 w-[170px]"
                  onClick={() => fetchEmailTemplate(EmailTemplateType.learnerConfirmation)}
                  disabled={isLoading}>
                  {isLoading && refSelectedEmailTemplateType.current === EmailTemplateType.learnerConfirmation ? (
                    <ButtonLoadingIndicator />
                  ) : (
                    'Learner confirmation'
                  )}
                </button>
                <button
                  className="bg-blue-500 text-white mt-4 p-2 rounded-lg hover:bg-blue-600 w-[180px]"
                  onClick={() => fetchEmailTemplate(EmailTemplateType.managerConfirmation)}
                  disabled={isLoading}>
                  {isLoading && refSelectedEmailTemplateType.current === EmailTemplateType.managerConfirmation ? (
                    <ButtonLoadingIndicator />
                  ) : (
                    'Manager confirmation'
                  )}
                </button>
                <button
                  className="bg-blue-500 text-white mt-4 p-2 rounded-lg hover:bg-blue-600 w-[150px]"
                  onClick={() => fetchEmailTemplate(EmailTemplateType.managerConsent)}
                  disabled={isLoading}>
                  {isLoading && refSelectedEmailTemplateType.current === EmailTemplateType.managerConsent ? (
                    <ButtonLoadingIndicator />
                  ) : (
                    'Manager consent'
                  )}
                </button>
                {isAdminPlus() && (
                  <button
                    className="bg-green-500 text-white mt-4 p-2 rounded-lg hover:bg-green-600 w-[60px]"
                    onClick={() => onUpdateProgram(selectedProgram?.slug || '')}
                    disabled={isSaving}>
                    {isSaving ? <ButtonLoadingIndicator /> : 'Save'}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {showEmailTemplate && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 w-3/4 h-3/4">
              {' '}
              <div className="flex justify-end text-2xl font-bold">
                <button onClick={() => setShowEmailTemplate(false)}>X</button>
              </div>
              <div className="flex flex-col text-2xl font-bold gap-[0px] pb-[4px] pt-[8px]">
                <div className="flex flex-col items-center">
                  <div className="flex flex-row gap-[16px] items-center w-full">
                    <div className="w-[70px]" />
                    <span className="text-center flex-grow">Email template: {renderEmailTemplateTitle()}</span>
                    <button
                      className="button-primary w-[70px]"
                      onClick={() => {
                        // reset refBody
                        refBody.current = {}
                        setShowSendEmail(true)
                      }}>
                      Test
                    </button>
                  </div>
                  <span className="text-[12px] font-normal">{renderEmailTemplateStatus()}</span>
                </div>
                <div className="text-[18px] font-normal">{emailTemplate?.subject}</div>
              </div>
              <div className="w-full min-h-[80%] max-h-[80%] h-[80%] overflow-y-auto">
                <iframe
                  title="email-template"
                  srcDoc={emailTemplate?.inlineHtml}
                  className="w-full h-full border border-gray-300 focus:outline-none"
                />
              </div>
            </div>
          </div>
        )}
        {showSendEmail && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 w-1/2 h-auto">
              <div className="flex justify-end text-2xl font-bold">
                <button onClick={() => setShowSendEmail(false)}>X</button>
              </div>
              <div className="flex flex-col gap-[0px] pb-[12px]">
                <div className="flex flex-col items-center gap-[8px] px-[32px]">
                  <span>Please note the No/Yes links in this test email will not work as it’s only a preview.</span>
                  <input
                    type="email"
                    id="email"
                    placeholder="Test email"
                    className="input-text"
                    onChange={e => {
                      refBody.current.email = e.target.value
                    }}
                  />
                  <input
                    type="text"
                    id="firstName"
                    placeholder="First name (optional)"
                    className="input-text"
                    onChange={e => {
                      refBody.current.firstName = e.target.value
                    }}
                  />
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Last name (optional)"
                    className="input-text"
                    onChange={e => {
                      refBody.current.lastName = e.target.value
                    }}
                  />
                  <input
                    type="text"
                    id="ManagerFirstName"
                    placeholder="Manager first name (optional)"
                    className="input-text"
                    onChange={e => {
                      refBody.current.managerFirstName = e.target.value
                    }}
                  />
                  <button className="button-primary" onClick={sendEmail} disabled={isSendingEmail}>
                    {isSendingEmail ? <ButtonLoadingIndicator /> : 'Send'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </SidebarLayout>
  )
}
